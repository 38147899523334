<template>
  <div
    class="login-container bg-ease d-flex align-items-center justify-content-center"
  >
    <v-form
      ref="form"
      class="form-container pa-9"
      @submit.prevent
    >
      <!-- <v-row no-gutters>
        <v-col class="logo">
          <img class="img-fluid" src="@/assets/images/logo.png">
        </v-col>
      </v-row> -->
      <!-- <v-row class="mt-n5" no-gutters>
        <v-col class="text-capitalize text-center text-h6 font-weight-normal font-italic white--text">
          {{ $t('page.login.subtitle') }}
        </v-col>
      </v-row> -->
      <v-row class="my-9" no-gutters>
        <v-col class="text-capitalize text-center text-h4 font-weight-medium white--text">
          {{ $t('login') }}
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col>
          <v-text-field
            v-model="username"
            dark
            solo
            autofocus
            :placeholder="$t('email')"
          />
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col>
          <v-text-field
            v-model="password"
            solo
            dark
            autocomplete
            :placeholder="$t('password')"
            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPwd ? 'text' : 'password'"
            @click:append="showPwd = !showPwd"
          />
        </v-col>
      </v-row>
      <!-- <v-row no-gutters>
        <v-col cols="6" class="pa-2 text-h6 font-weight-medium white--text">
          <router-link class="link" :to="{name:'SignUp'}">
            {{ $t('signup_immediately') }}<span
              v-if="$i18n.locale==='en'"
            >?</span>
          </router-link>
        </v-col>
        <v-col cols="6" class="text-right text-h6 pa-2 white--text">
          <router-link class="link" :to="{name:'ForgotPwd'}">
            {{ $t('forgot_pwd') }}<span
              v-if="$i18n.locale==='en'"
            >?</span>
          </router-link>
        </v-col>
      </v-row> -->
      <v-row class="mt-5" justify="center" align="center" no-gutters>
        <v-col cols="8" class="text-center">
          <v-btn
            class="font-weight-bold text-h5 primary--text"
            rounded
            color="white"
            type="submit"
            :loading="isLoadingLogin"
            style="width: 100%; height: 50px;"
            @click="prepareLogin"
          >
            {{ $t('login') }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row align="center">
        <v-col class="text-center">
          <div class="font-weight-regular white--text text-h7">
            {{ $t('login.social_login') }}
          </div>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="4" offset="2" class="text-center">
          <GoogleLogin :params="params" :on-success="onSuccess">
            <v-btn style="background-color: rgb(204, 88, 73); height: 27px">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 48 48"
              >
                <g>
                  <path
                    fill="#FFF"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  />
                  <path
                    fill="#FFF"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  />
                  <path
                    fill="#FFF"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  />
                  <path
                    fill="#FFF"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  />
                  <path fill="none" d="M0 0h48v48H0z" />
                </g>
              </svg>
            </v-btn>
          </GoogleLogin>
          <span class="ml-1 white--text text-overline">Google</span>
        </v-col>
        <v-col cols="4" class="text-center">
          <v-btn
            style="background-color: rgb(66, 88, 148); height: 27px"
            @click="fb_login"
          >
            <v-img
              src="@/assets/images/facebook-app-symbol.svg"
              max-height="18px"
              max-width="18px"
            />
          </v-btn>
          <span class="ml-1 white--text text-overline">Facebook</span>
        </v-col>
      </v-row> -->
    </v-form>
  </div>
</template>

<script>
// import GoogleLogin from 'vue-google-login'

import {
  apiCommonauthTokens,
  apiCommonauthUsers,
  apiCommonauthAdminUsers
} from '@/api'

export default {
  name: 'Login',
  // components: { GoogleLogin },
  data: () => ({
    showPwd: false,
    // only needed if you want to render the button with the google ui
    renderParams: {
      width: 250,
      height: 50,
      longtitle: true
    },
    username: '',
    password: '',
    isLoadingLogin: false,
    forgetPswUrl: new URL('mailto:service@ease-x.com?subject=Password reset&body=<please provide your username>').href
  }),

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  created() {
    if (localStorage.loginToken) {
      if (this.$route.query.redirect) {
        try {
          this.$router.push({ path: this.$route.query.redirect })

          return
        } catch (error) {
          this.$router.push({ name: 'Portal' })
        }
      }
      return this.$router.push({ name: 'Portal' })
    }
  },

  methods: {
    prepareLogin() {
      if (!this.password || !this.username) {
        return
      }

      const data = {
        username: this.username,
        password: this.password,
        method: 'password'
      }
      this.login(data)
    },
    onSuccess(googleUser) {
      this.bindAccount(
        googleUser.getBasicProfile().getEmail(),
        googleUser.getAuthResponse().id_token,
        'google'
      )
    },
    async login(data) {
      this.isLoadingLogin = true
      return apiCommonauthTokens
        .post(data)
        .then(res => {
          const token = res?.data?.data?.token
          if (!token) {
            return this.$store.dispatch('snackbar/showError', {
              content: this.$t('page.login.error_account_pwd')
            })
          }

          if (token === 'not_verified') {
            return this.$store.dispatch('snackbar/showError', {
              content: this.$t('page.verify.verify_not_yet')
            })
          }
          localStorage.loginToken = token

          if (this.$route.query.redirect) {
            try {
              this.$router.push({ path: this.$route.query.redirect })

              return
            } catch (error) {
              this.$router.push({ name: 'Portal' })
            }
          }
          return this.$router.push({ name: 'Portal' })
        })
        .catch(error => {
          if (error.message === 'auth error') {
            return this.$store.dispatch('snackbar/showError', {
              content: this.$t('page.login.error_account_pwd')
            })
          }

          return this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoadingLogin = false
        })
    },
    fb_login() {
      const vm = this
      // 檢查登入狀態
      window.FB.getLoginStatus(function(response) {
        // 登入狀態 - 已登入
        if (response.status === 'connected') {
          // 獲取用戶個人資料
          vm.getProfile(response)
        } else {
          // 登入狀態 - 未登入
          // 用戶登入(確認授權)
          window.FB.login(
            function(res) {
              // 獲取用戶個人資料
              vm.getProfile(res)
            },
            // 授權 - 個人資料&Email
            { scope: 'public_profile,email' }
          )
        }
      })
    },
    logout() {
      // 檢查登入狀態
      window.FB.getLoginStatus(function(response) {
        // 檢查登入狀態
        if (response.status === 'connected') {
          // 移除授權
          window.FB.api('/me/permissions', 'DELETE', function(res) {
            // 用戶登出
            window.FB.logout()
          })
        } else {
          // do something
        }
      })
    },
    async getProfile(response) {
      // console.log('response', response)
      const vue = this
      window.FB.api('/me?fields=id,email', function(res) {
        vue.bindAccount(
          res.email,
          response.authResponse.accessToken,
          'facebook'
        )
      })
    },
    async bindAccount(email, token, method) {
      // console.log(email, token, method)
      // 1. check email usage
      // 2.if yes do retrieve token
      // 3.if no create new user with this email
      // 4. redirec to portal
      let response = await apiCommonauthUsers.post({
        username: email
      })

      if (response.data.result !== 'exist') {
        const payload = {
          username: email,
          password: token,
          method: method
        }
        response = await apiCommonauthAdminUsers.post(payload)
        this.login(payload)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .form-container {
    width: 29%;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 10px;

    .v-input.v-text-field {
      border-radius: 6px;

      ::v-deep .v-input__slot {
        background-color: rgba(234, 234, 234, 0.5);

        .v-text-field__slot {
          .v-label,
          input {
            color: white;
          }
        }
      }
    }
  }

  .logo {
    text-align: center;
    border-style: solid;
    border-width: 0;
    border-color: white;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 5px;
    min-width: 29px;
  }

  .link {
    text-decoration: none;

    &:link {
      color: white;
    }

    &:visited {
      color: white;
    }

    &:hover {
      color: #36b;
    }

    &:active {
      color: #f00;
    }
  }
}

@media screen and (max-width: 1366px) and (min-width: 1025px) {
  .login-container {
    .form-container {
      width: 50%;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .login-container {
    .form-container {
      width: 69%;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 415px) {
  .login-container {
    .form-container {
      width: 95%;
    }
  }
}

@media screen and (max-width: 414px) {
  .login-container {
    .form-container {
      width: 100%;
    }
  }
}
</style>
